/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Grid, Typography, Button } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import { FC, useEffect, useState } from 'react';
import { useStyles } from './style';
import SliderScore from '@orientaction/components/SliderScore';
import { dataPreference, getFromLS, publicSvgPath, isAdmin } from '@orientaction/utils';
import { ModalCardPref } from '@orientaction/components';
import { apiServiceNative } from '../../../../utils/fetchNative';
import dateFormat from 'dateformat';
import { omit } from 'lodash';

interface IFirstView {
  listPrincipaux: any[];
  id?: string;
  handleUpdated?: (val: boolean) => void;
  updatedDate?: any;
  consultant?: any;
  upperHierarchie?: any;
  isPrefJeune?: any;
  initValue?: any;
}

const FirstView: FC<IFirstView> = ({
  listPrincipaux,
  id,
  handleUpdated,
  updatedDate,
  consultant,
  upperHierarchie,
  isPrefJeune = false,
  initValue,
}) => {
  const classe = useStyles();
  console.log({ listPrincipaux });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [pref, setPref] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const [newData, setNewData] = useState<any>({});
  const [pressEnter, setPressEnter] = useState<boolean>(false);

  const setValue = (elem: any) => {
    setPref(elem);
    setIsOpen(true);
  };
  const user = JSON.parse(getFromLS('userInfos') || '');
  console.log({ user });

  const handleCancel = () => {
    setIsEdit(false);
    setIsCanceled(true);
    setNewData({});
  };

  const handleNewData = (key: string, value: number) => {
    setNewData((prevData: any) => {
      return { ...prevData, [key]: value };
    });
  };
  const sendData = async () => {
    console.log({ newData });
    const dataToUpdated = newData;
    if (upperHierarchie) {
      dataToUpdated.consultant_id = upperHierarchie;
    }
    setIsEdit(false);
    const dataToPost = {
      ...omit(initValue, ['id', 'createdAt']),
      users_permissions_user: initValue?.users_permissions_user?.id,
      updatedDate: new Date().toISOString().split('T')[0],
    };
    const dataToEdit = {
      ...dataToUpdated,
      updatedDate: new Date().toISOString().split('T')[0],
    };
    try {
      if (!updatedDate) {
        const response = await apiServiceNative.post(`/api/preference-personality-results`, {
          data: dataToPost,
        });
      }
      const responseEdited = await apiServiceNative.put(
        `/api/preference-personality-results/${id}`,
        {
          data: dataToEdit,
        }
      );
      // console.log({ response, responseEdited });
      if (handleUpdated) {
        handleUpdated(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={classe.root}>
      <Grid container={true} spacing={2} className={classe.marginFistViewTop}>
        <Grid item={true} md={7} lg={7} xs={12} style={{ paddingBottom: 0 }}>
          <Typography
            variant="h4"
            color="primary"
            className={`${classe.title}`}
            style={{
              color: 'white',
              fontSize: 14,
              textTransform: 'none',
              textAlign: 'right',
              fontWeight: 500,
              marginRight: 1,
              marginBottom: 10,
            }}>
            {!isPrefJeune &&
              consultant &&
              consultant?.firstname &&
              consultant?.lastname &&
              updatedDate &&
              `Profil validé par ${consultant?.firstname} ${consultant?.lastname} le ${
                updatedDate && dateFormat(new Date(updatedDate), 'dd/mm/yyyy')
              }`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} spacing={2}>
        <Grid item={true} md={7} lg={7} xs={12}>
          <div className={classe.contentNoPadding}>
            <div className={classe.padding25} style={isAdmin(user) ? { paddingTop: 15 } : {}}>
              {!isPrefJeune && isAdmin(user) && !(updatedDate && !consultant?.id) && (
                <div className={classe.flexBtn}>
                  {isEdit && (
                    <Button
                      className={classe.btnCard}
                      onClick={handleCancel}
                      style={{ background: 'white', color: '#000A8C' }}>
                      Annuler
                    </Button>
                  )}
                  <Button
                    className={classe.btnCard}
                    onClick={() => (isEdit ? sendData() : setIsEdit(true))}
                    style={{ background: '#000A8C', color: 'white' }}>
                    {isEdit ? 'Valider' : 'Modifier'}
                  </Button>
                </div>
              )}
              <Typography
                variant="h4"
                color="primary"
                className={`${classe.title}`}
                style={{ color: 'black', fontSize: 23 }}>
                SYNTHÈSE DE VOS RÉSULTATS
              </Typography>
              <div style={{ marginTop: 15 }}>
                {listPrincipaux.map(elem => (
                  <SliderScore
                    pref={elem}
                    isEdit={isEdit}
                    isCanceled={isCanceled}
                    restoreCanceled={(val: boolean) => setIsCanceled(val)}
                    handleNewData={handleNewData}
                  />
                ))}
              </div>
            </div>
          </div>
        </Grid>

        <Grid
          item={true}
          container={true}
          md={5}
          lg={5}
          xs={12}
          direction="column"
          style={{ height: '100%' }}>
          <Grid item={true} xs={12} style={{ paddingBottom: 16 }}>
            <div className={classe.content2}>
              <Typography
                variant="h4"
                color="primary"
                className={`${classe.title}`}
                style={{ color: 'black', fontSize: 23 }}>
                VOS 5 PRÉFÉRENCES
              </Typography>

              <Typography
                variant="body2"
                color="primary"
                className={`${classe.description2}`}
                style={{ marginTop: 25 }}>
                Voici vos cinq préférences dans votre personnalité profonde. La personnalité
                profonde correspond à votre manière de penser et de se comporter quand vous n’êtes
                soumis(e) à aucune contrainte. <br /> <br />
                L’objectif est de trouver un métier et un environnement professionnel qui permettent
                d’exprimer vos préférences profondes. Bref, qui vous permettent d’être vous-même.
                Cela est aussi vrai dans votre vie personnelle.
              </Typography>
              <div className={classe.flexContainer}>
                {listPrincipaux?.map(elem => (
                  <Button
                    key={elem.category}
                    variant="contained"
                    style={{ background: dataPreference[elem.category].color, color: 'white' }}
                    onClick={() => setValue(elem)}
                    disableElevation={true}>
                    {elem.label}
                  </Button>
                ))}
              </div>
              <Typography
                variant="body2"
                color="primary"
                className={`${classe.description2}`}
                style={{ marginTop: 15, fontStyle: 'italic', color: 'black' }}>
                Cliquez sur une caractéristique pour en savoir + !
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <ModalCardPref
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        color={dataPreference[pref?.category]?.color}
        img={`${publicSvgPath}/${
          dataPreference[pref?.category] && dataPreference[pref?.category][pref?.key]?.img
        }`}
        label={pref?.label}
        title={pref?.title}
        colorSecondary={dataPreference[pref?.category]?.secondColor}
        opacity={!!dataPreference[pref?.category]?.opacity}
        value={!pref?.score[0].isMax ? pref?.score[1].pourcentage : pref?.score[0].pourcentage}
        text1={dataPreference[pref?.category] && dataPreference[pref?.category][pref?.key]?.text1}
        text2={dataPreference[pref?.category] && dataPreference[pref?.category][pref?.key]?.text2}
        list1={dataPreference[pref?.category] && dataPreference[pref?.category][pref?.key]?.list1}
        list2={dataPreference[pref?.category] && dataPreference[pref?.category][pref?.key]?.list2}
        youAre={pref?.youAre}
        listText={
          dataPreference[pref?.category] && dataPreference[pref?.category][pref?.key]?.listText
        }
      />
    </div>
  );
};

export default FirstView;
