/* eslint-disable prefer-destructuring */
/* eslint-disable react-hooks/exhaustive-deps */
import { LanguageActions } from '@orientaction/api-actions';
import { appLanguage } from '@orientaction/reducers';
import { userService } from '@orientaction/services';
import { getFromLS, getUserLanguageById, getUserLanguageFromBrowser } from '@orientaction/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

declare const Weglot: any;

const useLanguage = () => {
  const language = useSelector(appLanguage);

  const { lang: myLang, year }: any = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const token = localStorage.getItem('userToken') ? getFromLS('userToken') : '';
  const user = localStorage.getItem('userInfos') ? JSON.parse(getFromLS('userInfos') || '') : '';

  const getUserLanguage = async () => {
    if (user && user.id && token) {
      try {
        const { data } = await userService.getUserLanguage({ userId: user.id, token });
        if (!data?.data?.language?.id) {
          const lang: any = getUserLanguageFromBrowser();
          console.log({ langHere: lang });
          dispatch(LanguageActions.setLanguageApp(lang));
          await userService.modifyUser({
            token,
            language: lang === 'GB' ? 2 : 1,
            id: user?.id,
          });
          return;
        }
        const lang: any = getUserLanguageById(data.data.language.id);
        console.log({ lang, data: data.data });
        dispatch(LanguageActions.setLanguageApp(lang));
        Weglot.switchTo(lang === 'GB' ? 'en' : 'fr');
        handleUrl(lang);
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const onLanguageChange = async (code: string) => {
    await userService.modifyUser({
      token,
      language: code === 'GB' ? 2 : 1,
      id: user?.id,
      editBrevo: user?.company_user_role?.id === 5,
      email: user?.email,
    });
    Weglot.switchTo(code === 'GB' ? 'en' : 'fr');
    handleUrl(code);
    dispatch(LanguageActions.setLanguageApp(code));
  };

  const handleUrl = (code: string) => {
    const url = window.location.pathname;
    const newUrl = checkingUrl(url, code);
    history.replace(newUrl);
  };

  const checkingUrl = (urlToChek: string, code: string) => {
    let newUrl = urlToChek;
    if (code === 'GB') {
      if (urlToChek === '/mes-resultats') {
        newUrl = '/en/my-results';
      }
      if (urlToChek === '/mes-ressources') {
        newUrl = '/en/my-resources';
      }
      if (urlToChek.includes('/ma-boussole')) {
        newUrl = `/en/my-compass/${year}`;
      }
    } else {
      if (urlToChek === '/en/my-results') {
        newUrl = '/mes-resultats';
      }
      if (urlToChek === '/en/my-resources') {
        newUrl = '/mes-ressources';
      }
      if (urlToChek.includes('/en/my-compass')) {
        newUrl = `/ma-boussole/${year}`;
      }
    }
    return newUrl;
  };

  useEffect(() => {
    getUserLanguage();
  }, []);

  // useEffect(() => {
  //   if (myLang === 'en') {
  //     console.log({myLang})
  //     const updateLang = async () => {
  //       await userService.modifyUser({
  //         token,
  //         language: 2,
  //         id: user?.id,
  //       });
  //     };
  //     updateLang();
  //     Weglot.switchTo('en');
  //     dispatch(LanguageActions.setLanguageApp('GB'));
  //   }
  //   // else {
  //   //   const updateLang = async () => {
  //   //     await userService.modifyUser({
  //   //       token,
  //   //       language: 1,
  //   //       id: user?.id,
  //   //     });
  //   //   };
  //   //   updateLang();
  //   //   Weglot.switchTo('fr');
  //   //   dispatch(LanguageActions.setLanguageApp('FR'));
  //   // }
  // }, [myLang]);

  const translateWord = (word: string) => {
    let wordTranslate = word;
    return Weglot.translate(
      {
        words: [{ t: 1, w: word }],
        languageTo: language === 'GB' ? 'en' : 'fr',
      },
      function (data: any) {
        wordTranslate = data[0];
      },
    );
  };

  return {
    language,
    getUserLanguage,
    onLanguageChange,
    translateWord,
  };
};

export default useLanguage;
