/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { gameBookTemplateActions, gameVariablesTemplateActions } from '@orientaction/api-actions';
import ContainerAdmin from '@orientaction/commons/ContainerAdmin';
import MarginContainer from '@orientaction/commons/MarginContainer';
import { Layout, ItemVariable, Modal } from '@orientaction/components';
import TestMainImage from '@orientaction/components/Admin/Test/Create/TestMainInfo/LeftImage/TestMainImage';
import { gameBookValues, gameVariables, gameVariableValues } from '@orientaction/reducers';
import { getFromLS, publicIconPath } from '@orientaction/utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import style from '../style';
import OaLabel from '@orientaction/commons/OaLabel';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/HighlightOffOutlined';
import { cloneDeep, divide, pick } from 'lodash';
import { apiServiceNative } from '../../../utils/fetchNative';

const Variables = (props: any) => {
  const classes = style();
  const valuesBooksTemplate = useSelector(gameBookValues);
  const valuesVariables = useSelector(gameVariables);
  const valuesVariablesGame = useSelector(gameVariableValues);
  const token = getFromLS('userToken') || '';
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [variable, setVariable] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [variables, setVariables] = useState<any>([]);
  const [indexVariable, setIndexVariable] = useState<number>(0);
  const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);
  const { edit }: any = useParams();

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!variable.trim().length) {
      setError(true);
      return;
    }
    console.log({ entity_id: sessionStorage.getItem('id') });
    const { id: newId } = await apiServiceNative.post('/api/create-empty-variable', {
      name: variable,
      entity_id: sessionStorage.getItem('id'),
    });
    setVariables((oldValues: any) => {
      const copieOldValues = cloneDeep(oldValues);
      const newVariable = {
        id: newId,
        name: variable,
      };
      copieOldValues.push(newVariable);
      dispatch(gameVariablesTemplateActions.setAllVariables(copieOldValues));
      return copieOldValues;
    });
    setOpenDrawer(false);
    setVariable('');
  };

  const handleNameChange = (e: any) => {
    setVariable(e.target.value);
    if (error) {
      setError(false);
    }
  };
  useEffect(() => {
    if (variables) {
      setIndexVariable(variables.length + 1);
    }
  }, [variables]);

  const handleChangeName = (index: number, name: string) => {
    const newValues = cloneDeep(variables);
    newValues[index].name = name;
    setVariables(newValues);
    dispatch(gameVariablesTemplateActions.setAllVariables(newValues));
  };
  const handleDelete = (index: number) => {
    setVariables((oldValues: any) => {
      const newData = cloneDeep(oldValues);
      newData.splice(index, 1);
      dispatch(gameVariablesTemplateActions.setAllVariables(newData));
      return newData;
    });
  };

  const handleDeleteRestitution = (index: number) => {
    setVariables((oldValues: any) => {
      const newData = cloneDeep(oldValues);
      const newDataItem = pick(newData[index], ['name', 'id']);
      newData[index] = {
        ...newDataItem,
        contentForType1: 'normal',
        contentForType2: 'normal',
        citationAuthor1: '',
        citationAuthor2: '',
        citationContent1: '',
        citationContent2: '',
        p1: '',
        p2: '',
        informationEncyclopedia1: '',
        informationEncyclopedia2: '',
      };
      dispatch(gameVariablesTemplateActions.setAllVariables(newData));
      return newData;
    });
  };

  const handleSubmitVariables = () => {
    dispatch(gameBookTemplateActions.updateField('variables_restitution', variables));
    sessionStorage.setItem('gameVariables', JSON.stringify(variables));
    if (!edit) {
      history.push('/admin/game/create');
    } else {
      history.push('/admin/game/edit/the-game');
    }
    dispatch(gameVariablesTemplateActions.setAllVariables([]));
  };

  useEffect(() => {
    if (valuesVariables && valuesVariables.length > 0) {
      setVariables(valuesVariables);
    }
  }, [valuesVariables]);

  useEffect(() => {
    if (valuesBooksTemplate && valuesBooksTemplate?.variables_restitution?.length > 0) {
      if (!valuesVariables || valuesVariables?.length === 0) {
        dispatch(
          gameVariablesTemplateActions.setAllVariables(valuesBooksTemplate?.variables_restitution)
        );
      }
      // setVariables(valuesBooksTemplate?.variables_restitution);
    }
  }, [valuesBooksTemplate]);

  const confirmCancel = () => {
    if (!edit) {
      history.push('/admin/game/create');
    } else {
      history.push('/admin/game/edit/the-game');
    }
    dispatch(gameVariablesTemplateActions.setAllVariables([]));
  };

  return (
    <>
      <Layout isaddBackground={true} showOverFlow={true}>
        {loading && (
          <CircularProgress
            color="inherit"
            size={40}
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              zIndex: 3,
            }}
          />
        )}
        <ContainerAdmin>
          <MarginContainer big={true}>
            <Grid
              container={true}
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid item={true} sm={12}>
                <Typography variant="h2" style={{ textTransform: 'none', textAlign: 'center' }}>
                  Variables associées au jeu
                </Typography>
              </Grid>
            </Grid>
            <span className={classes.separator} />
            <Grid
              container={true}
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid item={true} sm={7} style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenDrawer(true)}
                    className={classes.btnPrimary}>
                    Ajouter une variable
                  </Button>
                </div>
              </Grid>
            </Grid>
            {variables && variables.length > 0 && (
              <Grid container={true} direction="row" justifyContent="center" alignItems="stretch">
                <Grid item={true} sm={5} />
                <Grid item={true} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography className={classes.summaryTextH6} variant="h6">
                    Restitutions
                  </Typography>
                </Grid>
              </Grid>
            )}
            <div className={classes.blockItemsVariable}>
              {variables && variables.length > 0 ? (
                variables.map((el: any, index: number) => (
                  <div key={`${el.name + index}`} style={{ marginTop: 10 }}>
                    <ItemVariable
                      key={`${el.name + index}`}
                      name={el.name}
                      index={index + 1}
                      handleChangeName={handleChangeName}
                      handleDelete={handleDelete}
                      dataVariable={el}
                      handleDeleteRestitution={handleDeleteRestitution}
                      edit={!!edit}
                    />
                  </div>
                ))
              ) : (
                <div className={classes.textEmptyVariable}>
                  <Typography variant="h5" style={{ textTransform: 'none', textAlign: 'center' }}>
                    Aucune variable
                  </Typography>
                </div>
              )}
            </div>
          </MarginContainer>
        </ContainerAdmin>
        <Drawer
          anchor="right"
          open={openDrawer}
          classes={{ root: classes.rootStyle, paper: classes.drawerContent }}>
          <div className={classes.headerContainer}>
            <IconButton className={classes.closeBtnDrawer} onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
            <div className={classes.titleContainer}>
              <Typography variant="h3" className={classes.titleDrawer}>
                Ajouter une variable
              </Typography>
            </div>
          </div>
          <div className={classes.contentChild}>
            <form onSubmit={handleSubmit}>
              <Typography className={classes.variableFormTitle} component="h3">
                Variable {`N°${indexVariable}`}
              </Typography>
              <div>
                <OaLabel>Nom de la variable</OaLabel>
                <TextField
                  id="test-variable-name"
                  variant="outlined"
                  className={classNames(classes.formInput, error && classes.error)}
                  name="variableName"
                  value={variable}
                  onChange={handleNameChange}
                />
              </div>
              <div className={classes.marginTop}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.btnPrimary}>
                  Sauvegarder
                </Button>
              </div>
            </form>
          </div>
        </Drawer>
      </Layout>
      <div className={classes.footerWrapper}>
        <div>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={() => setOpenModalCancel(true)}>
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="draft-submit"
            onClick={handleSubmitVariables}
            className={classes.btnPrimarySticky}>
            Sauvegarder
          </Button>
        </div>
      </div>
      <Modal
        data-cy="cancel-test-create-modal"
        open={openModalCancel}
        onCancel={() => setOpenModalCancel(false)}
        onConfirm={confirmCancel}
        textCancel="Non"
        title=""
        textValidate="Oui">
        <h3 style={{ color: '#000A8C' }}>Êtes-vous sur de vouloir annuler les modifications ?</h3>
      </Modal>
    </>
  );
};

export default Variables;
