/* eslint-disable import/no-unresolved */
/* eslint-disable simple-import-sort/imports */
import { Alert, ProtectedLazyRoute } from '@orientaction/commons';
import { useAlert } from '@orientaction/hooks';
import {
  AdminAddPassword,
  AdminCreateTest,
  AdminEbook1,
  AdminEbook3,
  AdminEbook5,
  AdminEbookUser,
  AdminEntreprise,
  AdminTest,
  AdminUserHarmonyResult,
  AdminUserResultat,
  BeneficiaryModifyPassword,
  Book,
  Book2,
  CreateEbook,
  CreateGame,
  CreateTest,
  CustomAuth,
  EditEbookMain,
  EditEbookPage,
  EditGame,
  EditPage,
  EditTest,
  EditVariable,
  Felicitations,
  ForgotPassword as ForgotPasswordCustom,
  FourthEbook,
  GestionCategorie,
  GlobalRestitution,
  GlobalRestitutionAdmin,
  HarmonyRedirect,
  Intro,
  ListEbook,
  ListGame,
  Login as LoginCustom,
  Main,
  MesRessources,
  MesResultats,
  MinorTest,
  ModelVariable,
  MonResultat,
  MonResultatHarmony,
  NeedsProResult,
  NeedsResult,
  PlayGame,
  PlayGameDisconnected,
  Profile,
  RedirectToLastResult,
  ResetPassword as ResetPasswordCustom,
  Result,
  ResultatBeneficiaire,
  ResultatMeanning,
  ResultatNeedPro,
  ResultatNeeds,
  ResultatPreference,
  ResultatStudy,
  Resultats,
  Results,
  Signup,
  TestComponent,
  TestMeaning,
  TestMeaningRedirect,
  TestNeeds,
  TestNeedsPro,
  TestNeedsProRedirect,
  TestNeedsRedirect,
  TestPageUser,
  TestPreference,
  TestPreferenceJeune,
  TestPreferenceRedirect,
  TestPresentation,
  TestSoftSkills,
  TestStudy,
  Test_Page,
  UserProfile,
  UserProfileEdit,
  UserTest,
  Variables,
  ViewEbook,
  ViewGame,
  ViewGameDisconnected,
} from '@orientaction/pages';
import { Route, Switch } from 'react-router-dom';

import SelecteTemplate from '../pages/AdminSeriousGame';
import ResultatPreferenceJeune from '../pages/PageResultat/ResultatPreference/indexJeune';

const Router = () => {
  const alert = useAlert();

  const handleClose = () => {
    alert.setCloseAlert();
  };

  return (
    <>
      <Switch>
        <ProtectedLazyRoute path="/" exact={true} component={LoginCustom} />
        <ProtectedLazyRoute path="/signup" exact={true} component={Signup} />
        <ProtectedLazyRoute path="/main" exact={true} component={Main} />
        <ProtectedLazyRoute path="/main/user/:id" exact={true} component={UserProfile} />
        <ProtectedLazyRoute path="/main/user/:id/edit" exact={true} component={UserProfileEdit} />
        <ProtectedLazyRoute path="/user/tests" exact={true} component={UserTest} />
        <ProtectedLazyRoute path="/profil" exact={true} component={Profile} />
        <ProtectedLazyRoute path="/results" component={Results} />
        <ProtectedLazyRoute path="/forgot-password" exact={true} component={ForgotPasswordCustom} />
        <ProtectedLazyRoute path="/reset-password" exact={true} component={ResetPasswordCustom} />

        <ProtectedLazyRoute
          path="/admin/test"
          exact={true}
          component={AdminTest}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/game/selected"
          exact={true}
          component={SelecteTemplate}
          isRootOnly={true}
        />
        <ProtectedLazyRoute
          path="/admin/game/selected/:id"
          exact={true}
          component={SelecteTemplate}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/game/add-page/:id/:position?"
          exact={false}
          component={SelecteTemplate}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/test/:id/edit"
          exact={true}
          component={AdminCreateTest}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/game/list"
          exact={true}
          component={ListGame}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/game/create"
          exact={true}
          component={CreateGame}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/game/edit-page/:id"
          exact={true}
          component={EditPage}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/game/edit-page"
          exact={true}
          component={EditPage}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/game/create-variables/:edit?"
          exact={true}
          component={Variables}
          isRootOnly={true}
        />
        <ProtectedLazyRoute
          path="/admin/game/model-variable/:id/:edit?"
          exact={true}
          component={ModelVariable}
          isRootOnly={true}
        />
        <ProtectedLazyRoute
          path="/admin/game/edit-variable/:id/:edit?"
          exact={true}
          component={EditVariable}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/ebook/edit/:id"
          exact={true}
          component={EditEbookPage}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/ebook/edit"
          exact={true}
          component={EditEbookPage}
          isRootOnly={true}
        />
        <ProtectedLazyRoute
          path="/admin/game/edit/:id"
          exact={true}
          component={EditGame}
          isRootOnly={true}
        />

        <ProtectedLazyRoute path="/game/view/:id/:idResult?" exact={true} component={ViewGame} />
        <ProtectedLazyRoute path="/game/play/:id/:idLastPage" exact={true} component={PlayGame} />
        <ProtectedLazyRoute
          path="/game/:id/:title?"
          exact={true}
          component={ViewGameDisconnected}
        />
        <ProtectedLazyRoute
          path="/play/:id/:title?"
          exact={true}
          component={PlayGameDisconnected}
        />

        <ProtectedLazyRoute
          path="/admin/ebook/list"
          exact={true}
          component={ListEbook}
          isRootOnly={true}
        />
        <ProtectedLazyRoute
          path="/admin/ebook/create"
          exact={true}
          component={CreateEbook}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/ebook/edit-main/:id?"
          exact={true}
          component={EditEbookMain}
          isRootOnly={true}
        />
        <ProtectedLazyRoute path="/ebook/view/:id?/:index?" exact={true} component={ViewEbook} />

        <ProtectedLazyRoute path="/test/:id/:title?" exact={true} component={TestPresentation} />
        <ProtectedLazyRoute path="/test" exact={true} component={Test_Page} />
        <ProtectedLazyRoute path="/congrats/:id" exact={true} component={Felicitations} />
        <ProtectedLazyRoute path="/resultats/:id" exact={true} component={Resultats} />
        <ProtectedLazyRoute path="/mon-resultat/:id" exact={true} component={Result} />

        <ProtectedLazyRoute path="/tests/soft-skills" exact={true} component={HarmonyRedirect} />
        <ProtectedLazyRoute
          path="/tests/preference-personality"
          exact={true}
          component={TestPreferenceRedirect}
        />
        <ProtectedLazyRoute path="/tests/needs-pro" exact={true} component={TestNeedsProRedirect} />
        <ProtectedLazyRoute path="/tests/needs" exact={true} component={TestNeedsRedirect} />
        <ProtectedLazyRoute
          path="/tests/meaning-value"
          exact={true}
          component={TestMeaningRedirect}
        />

        <ProtectedLazyRoute path="/tests/harmony" exact={true} component={TestSoftSkills} />
        <ProtectedLazyRoute path="/:lang/tests/harmony" exact={true} component={TestSoftSkills} />
        <ProtectedLazyRoute path="/tests/sens-valeurs" exact={true} component={TestMeaning} />
        <ProtectedLazyRoute
          path="/:lang/tests/meaning-values"
          exact={true}
          component={TestMeaning}
        />
        <ProtectedLazyRoute path="/tests/besoins" exact={true} component={TestNeeds} />
        <ProtectedLazyRoute path="/:lang/tests/needs" exact={true} component={TestNeeds} />
        <ProtectedLazyRoute path="/tests/besoins-pro" exact={true} component={TestNeedsPro} />
        <ProtectedLazyRoute path="/:lang/tests/needs-pro" exact={true} component={TestNeedsPro} />
        <ProtectedLazyRoute
          path="/tests/preferences-personnalites"
          exact={true}
          component={TestPreference}
        />
        <ProtectedLazyRoute
          path="/:lang/tests/preferences-personality"
          exact={true}
          component={TestPreference}
        />

        <ProtectedLazyRoute
          path="/tests/preferences-jeune"
          exact={true}
          component={TestPreferenceJeune}
        />
        <ProtectedLazyRoute
          path="/:lang/tests/preferences-jeune"
          exact={true}
          component={TestPreferenceJeune}
        />

        <ProtectedLazyRoute path="/tests/study" exact={true} component={TestStudy} />
        <ProtectedLazyRoute path="/:lang/tests/study" exact={true} component={TestStudy} />

        <ProtectedLazyRoute path="/tests" exact={true} component={TestPageUser} />

        <ProtectedLazyRoute path="/mes-resultats" exact={true} component={MesResultats} />
        <ProtectedLazyRoute path="/:lang/my-results" exact={true} component={MesResultats} />

        <ProtectedLazyRoute path="/mes-resultats/:id/:idUser?" exact={true} component={MonResultat} />

        <ProtectedLazyRoute
          path="/admin/test/create-test"
          exact={true}
          component={AdminCreateTest}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/test/creer"
          exact={true}
          component={CreateTest}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/test/:id/modifier"
          exact={true}
          component={EditTest}
          isRootOnly={true}
        />

        <ProtectedLazyRoute
          path="/admin/users-resultats"
          exact={true}
          isProtected={true}
          component={AdminUserResultat}
        />

        <ProtectedLazyRoute
          path="/admin/business-spaces"
          exact={true}
          component={AdminEntreprise}
        />

        <ProtectedLazyRoute path="/admin/add-password" exact={true} component={AdminAddPassword} />

        <ProtectedLazyRoute path="/mes-ressources" exact={true} component={MesRessources} />
        <ProtectedLazyRoute path="/:lang/my-resources" exact={true} component={MesRessources} />

        <ProtectedLazyRoute
          path="/admin/resultat-beneficiaire/:id"
          exact={true}
          component={ResultatBeneficiaire}
        />

        <ProtectedLazyRoute
          path="/admin/ressources-beneficiaire/:id"
          exact={true}
          component={AdminEbookUser}
        />

        <ProtectedLazyRoute path="/admin/ebook1-user/:id" exact={true} component={AdminEbook1} />
        <ProtectedLazyRoute path="/admin/ebook3" exact={true} component={AdminEbook3} />
        <ProtectedLazyRoute path="/ebook-intro/:id/:readOnly?/:index?" exact={true} component={Intro} />
        <ProtectedLazyRoute
          path="/:lang/ebook-intro/:id/:readOnly?/:index?"
          exact={true}
          component={Intro}
        />

        <ProtectedLazyRoute path="/ebook-etape-1/:id/:readOnly?/:index?" exact={true} component={Book} />
        <ProtectedLazyRoute
          path="/:lang/ebook-step-1/:id/:readOnly?/:index?"
          exact={true}
          component={Book}
        />
        <ProtectedLazyRoute path="/ebook-etape-2/:id/:readOnly?/:index?" exact={true} component={Book2} />
        <ProtectedLazyRoute
          path="/:lang/ebook-step-2/:id/:readOnly?/:index?"
          exact={true}
          component={Book2}
        />

        <ProtectedLazyRoute
          path="/ebook-etape-3/:id/:readOnly?/:index?"
          exact={true}
          component={AdminEbook3}
        />
        <ProtectedLazyRoute
          path="/:lang/ebook-step-3/:id/:readOnly?/:index?"
          exact={true}
          component={AdminEbook3}
        />

        <ProtectedLazyRoute
          path="/ebook-etape-4/:id/:readOnly?/:index?"
          exact={true}
          component={FourthEbook}
        />
        <ProtectedLazyRoute
          path="/:lang/ebook-step-4/:id/:readOnly?/:index?"
          exact={true}
          component={FourthEbook}
        />

        <ProtectedLazyRoute
          path="/ebook-etape-5/:id/:readOnly?/:index?"
          exact={true}
          component={AdminEbook5}
        />
        <ProtectedLazyRoute
          path="/:lang/ebook-step-5/:id/:readOnly?/:index?"
          exact={true}
          component={AdminEbook5}
        />

        <ProtectedLazyRoute path="/do-test/:id/:title?" exact={true} component={Test_Page} />
        <ProtectedLazyRoute path="/test/:id/passer/:title?" exact={true} component={MinorTest} />

        <ProtectedLazyRoute
          path="/admin/gestion-categorie"
          exact={true}
          component={GestionCategorie}
        />

        <ProtectedLazyRoute
          path="/resultat-harmony/:id"
          exact={true}
          component={MonResultatHarmony}
        />
        <ProtectedLazyRoute
          path="/resultat-harmony"
          exact={true}
          component={RedirectToLastResult}
        />

        <ProtectedLazyRoute
          path="/admin/user-harmony-result/:id"
          exact={true}
          component={AdminUserHarmonyResult}
        />

        <ProtectedLazyRoute path="/resultat-need-pro/:id" exact={true} component={NeedsProResult} />

        <ProtectedLazyRoute
          path="/resultat-needs-pro/:id"
          exact={true}
          component={ResultatNeedPro}
        />

        <ProtectedLazyRoute
          path="/resultat-need-pro"
          exact={true}
          component={RedirectToLastResult}
        />

        <ProtectedLazyRoute
          path="/resultat-preference/:id"
          exact={true}
          component={ResultatPreference}
        />

        <ProtectedLazyRoute
          path="/res-pref-j/:id"
          exact={true}
          component={ResultatPreferenceJeune}
        />

        <ProtectedLazyRoute
          path="/resultat-preference"
          exact={true}
          component={RedirectToLastResult}
        />

        <ProtectedLazyRoute path="/resultat-needs/:id" exact={true} component={ResultatNeeds} />
        <ProtectedLazyRoute path="/resultat-need/:id" exact={true} component={NeedsResult} />
        <ProtectedLazyRoute path="/resultat-need" exact={true} component={RedirectToLastResult} />

        <ProtectedLazyRoute
          path="/resultat-meaning-value/:id"
          exact={true}
          component={ResultatMeanning}
        />

        <ProtectedLazyRoute
          path="/resultat-meaning-value"
          exact={true}
          component={RedirectToLastResult}
        />

        <ProtectedLazyRoute path="/resultat-study/:id" exact={true} component={ResultatStudy} />
        <ProtectedLazyRoute path="/resultat-study" exact={true} component={RedirectToLastResult} />
        <ProtectedLazyRoute
          path="/mon-resultat-du-test-study/:id"
          exact={true}
          component={ResultatStudy}
        />

        <ProtectedLazyRoute path="/test-component" exact={true} component={TestComponent} />

        <ProtectedLazyRoute path="/ma-boussole/:year" exact={true} component={GlobalRestitution} />
        <ProtectedLazyRoute
          path="/:lang/my-compass/:year"
          exact={true}
          component={GlobalRestitution}
        />
        <ProtectedLazyRoute
          path="/admin/:userId/boussole/:year"
          exact={true}
          component={GlobalRestitutionAdmin}
        />

        <Route path="/user/modify-password" exact={true} component={BeneficiaryModifyPassword} />
        <ProtectedLazyRoute path="/resultat-test-besoins-pro" exact={true} component={CustomAuth} />
        <ProtectedLazyRoute
          path="/resultat-test-sens-valeurs"
          exact={true}
          component={CustomAuth}
        />
        <ProtectedLazyRoute path="/resultat-test-besoins" exact={true} component={CustomAuth} />
        <ProtectedLazyRoute path="/resultat-test-preferences" exact={true} component={CustomAuth} />
        <ProtectedLazyRoute path="/resultat-test-preferences" exact={true} component={CustomAuth} />
        <ProtectedLazyRoute path="/resultat-test-harmony" exact={true} component={CustomAuth} />
        <ProtectedLazyRoute path="/resultat-test-study" exact={true} component={CustomAuth} />
      </Switch>

      <Alert
        open={alert.open}
        handleClose={handleClose}
        severity={alert.severity}
        message={alert.message}
      />
    </>
  );
};

export default Router;
