// eslint-disable-next-line import/no-unresolved
import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const url = config.api_url;

export const getCompanies = async (token: string) => {
  return makeRequest(`${url}/api/get-company`, 'GET', {}, getHeaders(token));
};

export const addCompanies = async (token: string, data: any) => {
  return makeRequest(`${url}/api/create-company`, 'POST', { ...data }, getHeaders(token));
};

export const updateCompanies = async (token: string, data: any) => {
  return makeRequest(`${url}/api/update-company/${data.id}`, 'PUT', { ...data }, getHeaders(token));
};

export const CompaniesDisabled = async (token: string, data: any) => {
  return makeRequest(
    `${url}/api/company-disabled-all?isActivated=${data.isActivated}&id=${data.id}`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const deleteCompanies = async (token: string, id: string) => {
  return makeRequest(`${url}/api/company-delete/${id}`, 'DELETE', {}, getHeaders(token));
};

// export const getCompany = async (token: string, id: number) => {
//   return makeRequest(
//     `${url}/api/companies/${id}?populate[users][populate][0]=company_user_role&populate[users][populate][1]=upper_hierarchy&populate[users][populate][2]=subordinates.subordinates.subordinates.subordinates&populate[users][populate][3]=subordinates.company_user_role`,
//     'GET',
//     {},
//     getHeaders(token)
//   );
// };

interface IGetCompany {
  token: string;
  company: number;
  company_user_role: number;
  page: number;
  filter: string;
  isActivated: boolean;
  rattachement: boolean;
}

export const getCompany = async (param: IGetCompany) => {
  return makeRequest(
    `${url}/api/company-user-role?company=${param.company}&company_user_role=${param.company_user_role}&page=${param.page}&filter=${param.filter}&isActivated=${param.isActivated}&rattachement=${param.rattachement}`,
    'GET',
    {},
    getHeaders(param.token),
  );
};

// export const getCompany = async (param: IGetCompany) => {
//   return makeRequest(
//     `${url}/api/get-company-data?company=${param.company}&company_user_role=${param.company_user_role}&page=${param.page}&filter=${param.filter}&isActivated=${param.isActivated}&rattachement=${param.rattachement}`,
//     'GET',
//     {},
//     getHeaders(param.token),
//   );
// };

// export const getCompany = async (param: IGetCompany) => {
//   return makeRequest(
//     `${url}/api/companies/${param.company}?populate[users][populate][0]=company_user_role&populate[users][populate][1]=upper_hierarchy&populate[users][populate][2]=subordinates.subordinates.subordinates.subordinates&populate[users][populate][3]=subordinates.company_user_role`,
//     'GET',
//     {},
//     getHeaders(param.token)
//   );
// };

export const getAdminUser = async (token: string, id: number) => {
  return makeRequest(
    `${url}/api/companies/${id}?populate[users][populate][0]=company_user_role&populate[users][populate][1]=upper_hierarchy&populate[users][populate][2]=subordinates.subordinates.subordinates.subordinates&populate[users][populate][3]=subordinates.company_user_role`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const getfilter = async (token: string, data: any) => {
  return makeRequest(`${url}/api/company-filter`, 'POST', { ...data }, getHeaders(token));
};

export const getPopulateHierachy = async (token: string, id: number) => {
  return makeRequest(`${url}/api/company-user-admin-populate`, 'POST', { id }, getHeaders(token));
};

export const getUpHierachy = async (token: string, data: any) => {
  return makeRequest(
    `${url}/api/company-user-role-get-up-hierachy`,
    'POST',
    { ...data },
    getHeaders(token),
  );
};

interface IGetZoneInfluance {
  token: string;
  company_user_role: number;
  page: number;
  filter: string;
  idUserconnected: string;
  isActivated: boolean;
  rattachement: boolean;
}

export const getZoneInfuance = async (param: IGetZoneInfluance) => {
  return makeRequest(
    `${url}/api/company-user-admin-zone-infuance?company_user_role=${param.company_user_role}&page=${param.page}&filter=${param.filter}&idUserconnected=${param.idUserconnected}&isActivated=${param.isActivated}&rattachement=${param.rattachement}`,
    'GET',
    {},
    getHeaders(param.token),
  );
};

export const getCompanyUserRole = async (token: string) => {
  return makeRequest(`${url}/api/company-user-roles`, 'GET', {}, getHeaders(token));
};

export const getReferentUserRole = async (token: string, id: number) => {
  return makeRequest(
    `${url}/api/users?filters[company_user_role][id][$eq]=${id}`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const updateCompanyUserPassword = async (data: any) => {
  return makeRequest(`${url}/api/company-user-admin-update-password`, 'PUT', data);
};
