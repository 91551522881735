import { Button, CircularProgress, Grid, TextField, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { Layout } from '@orientaction/components';
import { getFromLS } from '@orientaction/utils';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { apiServiceNative } from '../../../utils/fetchNative';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import style from '../style';

const ViewGameDisconnected = (props: any) => {
  const classes = style();
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const token = getFromLS('userToken') || '';
  const user = JSON.parse(getFromLS('userInfos') || '{}');
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { id, idResult }: any = useParams();
  const [game, setGame] = useState<any>([]);

  useEffect(() => {
    const fetchGameDetails = async () => {
      setLoading(true);
      try {
        const response = await apiServiceNative.get(`/api/details-game/${id}`);
        if (response && response.pages && response.pages.length > 0) {
          setGame(response);
          setTitle(response.title);
          setUrl(response.couverture);
          console.log({ response });
          // setUrl()
        } else {
          console.error('Invalid response structure:', response);
        }
      } catch (error) {
        console.error('Error fetching game details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameDetails();
  }, [id]);

  const viewGame = async () => {
    setLoading(true);

    try {
      // put redirection /create-result-game
      if (token && user) {
        const response = await apiServiceNative.post('/api/create-result-game', { gameId: id });
        const deletedGameResult = await apiServiceNative.post(
          `/api/delete-result-game-not-needed`,
          {
            user_id: user.id,
            idResult: response.idResult,
            idGame: id,
          }
        );
        history.push(`/game/play/${response.idResult}/0`);
      } else {
        const response = await apiServiceNative.post('/api/create-result-game-without-user', { gameId: id });
        history.push(`/play/${response.idResult}/${title}`);
      }
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de édition du jeu :', error);
      setLoading(false);
    }
  };

  const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const goBackRessource = () => {
    if (isXS) {
      sessionStorage.setItem('redirect', JSON.stringify(['seriousgame', id]));
    }
    history.push('/mes-ressources');
  };

  return (
    <div className={classes.grayBGContent}>
      <div className={classes.containerDiv}>
        {loading ? (
          <CircularProgress
            size={40}
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              zIndex: 3,
              color: 'white',
            }}
          />
        ) : (
          <Grid container={true} direction="row" justifyContent="center" alignItems="center">
            <Grid
              item={true}
              sm={12}
              xs={12}
              style={{ display: 'flex' }}
              className={classes.btnContainer}
              justifyContent="center"
              alignItems="center">
              <Button
                className={classes.btnFull}
                style={{ background: 'white' }}
                onClick={viewGame}>
                Commencer
              </Button>
            </Grid>
            <Grid item={true} sm={4} xs={12}>
              <div className={classes.containerCover}>
                <div className={classes.content}>
                  <Typography variant="h4" className={classes.title}>
                    {title}
                  </Typography>
                  <div className={classes.containerElement}>
                    <img className={classes.img} src={url} alt="page covurture" />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default ViewGameDisconnected;
