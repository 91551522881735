/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-return */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Layout } from '@orientaction/components';
import Model1 from '@orientaction/components/Admin/Game/ModelPageUser/Model1';
import Model2 from '@orientaction/components/Admin/Game/ModelPageUser/Model2';
import Model3 from '@orientaction/components/Admin/Game/ModelPageUser/Model3';
import Model4 from '@orientaction/components/Admin/Game/ModelPageUser/Model4';
import Model5 from '@orientaction/components/Admin/Game/ModelPageUser/Model5';
import Model6 from '@orientaction/components/Admin/Game/ModelPageUser/Model6';
import Model7 from '@orientaction/components/Admin/Game/ModelPageUser/Model7';
import { getFromLS } from '@orientaction/utils';
import { find, includes } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { apiServiceNative } from '../../../utils/fetchNative';
import { ReactComponent as Arrow } from '../../../Asset/svg/arrow_right.svg';
import ClearIcon from '@material-ui/icons/Clear';
import style from '../style';

const PlayGameDisconnected = (props: any) => {
  const classes = style();
  const [warningsListData, setWarningList] = useState('');
  const [openGame, setOpenGame] = useState(false);
  const [gameEdit, setGameEdit] = useState<any>(null);
  const [allPages, setPages] = useState<any>(null);
  const [pageId, setPageId] = useState(0);
  const [page, setPage] = useState<any>(null);
  const token = getFromLS('userToken') || '';
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { id, idLastPage }: any = useParams();
  const [pagesViewed, setPagesViewed] = useState<any>();
  const [progress, setProgress] = useState<number>();
  const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const [variablesSelected, setVariablesSelected] = useState<any>([]);
  const [openModalDisconnected, setOpenModalDisconnected] = useState<boolean>(false)

  const renderModelCondition = (idModele: number, pageToShow: any) => {
    switch (idModele) {
      case 1:
        return (
          <Model1
            page={pageToShow}
            handleChooseDestion={goToDestination}
            handleChooseVariable={checkVariableSelected}
            endGame={endGame}
            endGameVariable={endGameVariable}
          />
        );
      case 2:
        return (
          <Model2
            page={pageToShow}
            handleChooseDestion={goToDestination}
            handleChooseVariable={checkVariableSelected}
            endGame={endGame}
            endGameVariable={endGameVariable}
          />
        );
      case 3:
        return (
          <Model3
            page={pageToShow}
            handleChooseDestion={goToDestination}
            handleChooseVariable={checkVariableSelected}
            endGame={endGame}
            endGameVariable={endGameVariable}
          />
        );
      case 4:
        return (
          <Model4
            page={pageToShow}
            handleChooseDestion={goToDestination}
            handleChooseVariable={checkVariableSelected}
            endGame={endGame}
            endGameVariable={endGameVariable}
          />
        );
      case 5:
        return (
          <Model5
            page={pageToShow}
            handleChooseDestion={goToDestination}
            handleChooseVariable={checkVariableSelected}
            endGame={endGame}
            endGameVariable={endGameVariable}
          />
        );
      case 6:
        return (
          <Model6
            page={pageToShow}
            handleChooseDestion={goToDestination}
            handleChooseVariable={checkVariableSelected}
            endGame={endGame}
            endGameVariable={endGameVariable}
          />
        );
      case 7:
        return (
          <Model7
            page={pageToShow}
            handleChooseDestion={goToDestination}
            handleChooseVariable={checkVariableSelected}
            endGame={endGame}
            endGameVariable={endGameVariable}
          />
        );
      case 8:
        return (
          <div className={classes.containerModel1}>
            <Grid
              container={true}
              justifyContent="center"
              alignItems="center"
              style={{ height: '100%' }}>
              <Grid
                item={true}
                justifyContent="center"
                alignItems="center"
                sm={6}
                className={classes.blockContentParent}>
                <Button
                  size="small"
                  color="primary"
                  disableRipple={true}
                  className={classes.btnCenter}
                  style={{ background: '#000A8C', height: 'auto' }}
                  onClick={endGameVariable}
                  endIcon={<Arrow className={classes.btnicon} />}>
                  Retour à la liste des ressources
                </Button>
              </Grid>
            </Grid>
          </div>
        );
      default:
        return;
    }
  };

  const validateArrayObjects = (array: any): any => {
    const errors: any[] = [];
    let hasEndGame = false;
    const validIds = array.map((item: any) => item.id);

    array.forEach((item: any, index: any) => {
      if (item.typeChoice === 'end_game') {
        hasEndGame = true;
      } else if (!item.choice || item.choice.length === 0) {
        errors.push(
          `Erreur dans la page intitulée "${item.title || `P${index + 1}`}": Le choix est vide.`
        );
      } else {
        item.choice.forEach((choice: any, choiceIndex: any) => {
          if (!choice.destination && !choice.destinationUrl) {
            errors.push(
              `Erreur dans la page intitulée "${item.title || `P${index + 1}`}", choix numéro ${
                choiceIndex + 1
              }: Destination et destinationUrl sont manquants.`
            );
          }

          if (choice.destination && !validIds.includes(choice.destination)) {
            errors.push(
              `Erreur dans la page intitulée "${item.title || `P${index + 1}`}", choix numéro ${
                choiceIndex + 1
              }: La page de destination ${choice.destination} n'est pas trouvée dans la liste.`
            );
          }
        });
      }
    });

    if (!hasEndGame) {
      errors.push("Ce jeu n'a pas de fin.");
    }

    return errors;
  };

  useEffect(() => {
    const fetchGameDetails = async () => {
      setLoading(true);
      try {
        const response = await apiServiceNative.get(`/api/game-front-user/${id}`);
        setPages(response?.pages);
        if (response && response.pages && response.pages.length > 0) {
          console.log({ response });
          setGameEdit(response);
          setPagesViewed(response.idViewedPages);
          setVariablesSelected(response.idViewedVariables);
          setProgress(progress);
          if (response?.last_id_page_visited !== 0) {
            setPage(find(response.pages, { id: response?.last_id_page_visited }));
          } else {
            setPage(response.pages[0]);
          }
          // setUrl()
        } else {
          console.error('Invalid response structure:', response);
        }
      } catch (error) {
        console.error('Error fetching game details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameDetails();
  }, [id]);

  useEffect(() => {
    if (allPages?.length > 0) {
      if (validateArrayObjects(allPages)?.length > 0) {
        setOpenGame(true);
        setWarningList(validateArrayObjects(allPages) as any);
      }
    }
  }, [allPages]);

  const goToDestination = async (idDestination: any) => {
    if (idDestination === -1) {
      await restartGameToCover();
      return;
    }
    setPage(find(gameEdit.pages, { id: idDestination }));
  };
  const checkVariableSelected = async (idVariableSelected: any) => {
    console.log({ idVariableSelected });
    if (idVariableSelected) {
      console.log({ variablesSelected });
      setVariablesSelected([...variablesSelected, idVariableSelected]);
    }
  };

  useEffect(() => {
    const updateLastViewPage = async (pageToEdit: any, gameToEdit: any) => {
      let dataToSend: any = !pageToEdit?.isVariable
        ? { last_id_page_visited: pageToEdit.id, date_consultation: new Date() }
        : {};
      // console.log({gameEditIdpages: gameToEdit.idViewedPages, pageEdit: pageToEdit.id, check: !gameToEdit.idViewedPages.includes(pageToEdit.id)})
      if (pagesViewed.length === 0 || !includes(pagesViewed, pageToEdit.id)) {
        console.log({ gameEditIdpages: pagesViewed, pageEdit: pageToEdit.id, variablesSelected });
        const idViewedPages: any = !pageToEdit?.isVariable
          ? [...pagesViewed, pageToEdit.id]
          : pagesViewed;
        dataToSend = {
          ...dataToSend,
          idViewedPages: idViewedPages?.join(','),
          idViewedVariables: variablesSelected?.join(','),
        };
      }
      const updatedGameEdit: any = await apiServiceNative.put(
        `/api/update-result-game/${gameEdit?.idResult}`,
        dataToSend
      );
      setPagesViewed(updatedGameEdit.idViewedPages.split(',').map(Number));
      setVariablesSelected(updatedGameEdit.idViewedVariables?.split(',')?.map(Number) || []);
      setProgress(updatedGameEdit.progress);
      console.log({ updatedGameEdit });
    };
    if (page && gameEdit) {
      updateLastViewPage(page, gameEdit);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [page, gameEdit]);

  const mostFrequentElement = (arr: any) => {
    const countMap: Record<number, number> = arr.reduce((acc: any, val: any) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {} as Record<number, number>);

    // Trouver la fréquence maximale
    const maxFrequency = Math.max(...Object.values(countMap));

    // Filtrer les éléments ayant la fréquence maximale
    const mostFrequentItems = Object.entries(countMap)
      .filter(([_, count]) => count === maxFrequency)
      .map(([i, count]) => ({ id: Number(i), number: count }));

    // Vérifier s'il y a une égalité et préparer le retour
    const equality = mostFrequentItems.length > 1;
    const result = equality
      ? { id: mostFrequentItems[0].id, number: maxFrequency, equality: true }
      : { ...mostFrequentItems[0], equality: false };
    return result;
  };

  const endGame = async () => {
    if (gameEdit?.typeGame === 'game') {
      console.log({ variablesSelected });
      const { id: idFrequent, equality } = mostFrequentElement(variablesSelected);
      const variableRestToShow = find(gameEdit?.variables_restitution, { id: idFrequent });
      if (!equality) {
        setPage({ ...variableRestToShow, isVariable: true });
      } else {
        setPage({ ...variableRestToShow, isVariable: true, template_game: 8 });
      }
      return;
    }
    endGameVariable();
  };

  const endGameVariable = async () => {
    const updatedGameEdit = await apiServiceNative.put(
      `/api/restart-game-result/${gameEdit?.idResult}`,
      {}
    );
    goBackRessource();
  };

  const goBackRessource = () => {
    if (isXS) {
      sessionStorage.setItem('redirect', JSON.stringify(['seriousgame', gameEdit.idGame]));
    }
    history.push('/mes-ressources');
  };

  const restartGameToCover = async () => {
    const updatedGameEdit = await apiServiceNative.put(
      `/api/restart-game-result/${gameEdit?.idResult}`,
      {}
    );
    history.push(`/game/view/${gameEdit?.idGame}/${gameEdit?.idResult}`);
  };

  useEffect(() => {
    if (pagesViewed && pagesViewed.length >= 4) {
      setOpenModalDisconnected(true);
    }
  }, [pagesViewed]);

  const goToSignupPage = () => {
    history.push(`/signup?from=test&idResult=${gameEdit?.idResult}&idGame=${gameEdit?.idGame}`);
  };

  return (
    <>
      <div className={classes.grayBGContent}>
        <div className={classes.containerModel}>
          <div style={{ width: '100%', marginTop: 75 }}>
            {isXS && (
              <div className={classes.progressBar}>
                <LinearProgress
                  classes={{
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: classes.barColorPrimary,
                  }}
                  variant="determinate"
                  value={progress}
                />
              </div>
            )}

            {loading ? (
              <CircularProgress
                size={40}
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  zIndex: 3,
                  color: 'white',
                }}
              />
            ) : (
              renderModelCondition(page?.template_game, page)
            )}
            {!isXS && (
              <div className={classes.progressBar}>
                <LinearProgress
                  classes={{
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: classes.barColorPrimary,
                  }}
                  variant="determinate"
                  value={progress}
                />
              </div>
            )}
          </div>
        </div>
        <Dialog
          open={openModalDisconnected}
          onClose={() => setOpenModalDisconnected(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            className={classes.iconBoutton}
            onClick={() => setOpenModalDisconnected(false)}>
            <ClearIcon fontSize="small" />
          </IconButton>
          <DialogContent>
            <DialogTitle
              id="alert-dialog-title"
            >
              Vous n&apos;êtes pas connecté
            </DialogTitle>
            <DialogContentText id="alert-dialog-description">
              Pour continuer le jeu, nous vous invitons à vous connecter ou à créer un compte Orient Action.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={goToSignupPage} color="primary" autoFocus={true}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default PlayGameDisconnected;
