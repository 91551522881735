/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Typography from '@material-ui/core/Typography';
import { LayoutResultat } from '@orientaction/layouts';
import { Button, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useResponseUser from '../../hooks/useResponseUser';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getFromLS } from '../../utils/utils';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { useStyles } from './style';
import { useAlert, useRedirectToRootPage } from '@orientaction/hooks';
import SimplePie from '@orientaction/components/Beneficiary/Result/SimplePie';
import PieDiagramContainer from '@orientaction/components/Beneficiary/Result/Pie';
import HorizontalDiagramContainer from '@orientaction/components/Beneficiary/Result/Horizontal';
import MinorTestHistory from './History';
import { ArrowBackIos } from '@material-ui/icons';
import { isAdminVerify } from '@orientaction/utils';

Chart.register(ChartDataLabels);

const MonResultat = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();
  const [results, setResults] = useState([]);
  const [userResponse, setUserResponse] = useState(undefined);
  const [test, setTest] = useState<any>();
  const [variables, setVariables] = useState([]);

  const { getResponseTestUser, getResponseTestUserGroupById, getTestVariables } = useResponseUser();
  const dataParams: any = useParams();
  const user = JSON.parse(getFromLS('userInfos') || '');
  const [loadPage, setLoadPage] = useState(true);

  const { goToRootPage } = useRedirectToRootPage();
  const alert = useAlert();

  const { push } = useHistory();

  const goBack = () => {
    // history.back();
    const to = isAdminVerify()
      ? `/admin/resultat-beneficiaire/${dataParams.idUser}`
      : '/mes-resultats';
    push(to);
  };

  // Static statement to switch softskills view and simple test view
  useEffect(() => {
    (async () => {
      setLoadPage(true);
      if (dataParams.id) {
        try {
          const oneResponseTest = await getResponseTestUser(dataParams.id);
          const variableResponse = await getTestVariables(oneResponseTest.attributes.test.data.id);
          setVariables(variableResponse.data);
          setUserResponse(oneResponseTest);
          setTest(oneResponseTest.attributes.test.data);

          console.log({ oneResponseTest });

          const allResponseUser = await getResponseTestUserGroupById(
            !dataParams.idUser ? user.id : dataParams.idUser,
            oneResponseTest.attributes.test.data.id
          );

          const resultsResponse = allResponseUser.map((result: any) => {
            return {
              ...result,
              realization_date: result.attributes.createdAt,
            };
          });
          setResults(resultsResponse);
        } catch (error) {
          if (
            error?.response?.data?.error?.name === 'PolicyError' &&
            error?.response?.data?.error?.status === 403
          ) {
            alert.openSimpleAlert('Cette page ne vous est pas accessible.');
            return goToRootPage();
          }
          alert.openSimpleAlert();
        } finally {
          setLoadPage(false);
        }
      }
    })();
  }, [dataParams.id]);

  const isSimplePie = test?.attributes?.diagram?.data?.id === 1;

  return (
    <LayoutResultat
      showBottomLines={!(isMobile && isSimplePie)}
      showToplines={!(isMobile || isSimplePie)}
      isGradient={true}
      isNew2={false}
      isTransparent={true}>
      {loadPage ? (
        <div className={classes.bodycenter}>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          <Button
            onClick={goBack}
            startIcon={<ArrowBackIos />}
            variant="outlined"
            className={classes.button}>
            Retour
          </Button>
          <div className={classes.paddingContent}>
            <div className={classes.btnHistory}>
              <MinorTestHistory
                testResults={results}
                idTest={test?.attributes?.diagram?.data?.id}
                toRedirect="/mes-resultats"
                idUser={dataParams.idUser}
              />
            </div>
            {userResponse && isSimplePie && <SimplePie userResponse={userResponse} />}
            {userResponse && test?.attributes?.diagram?.data?.id === 2 && (
              <>
                <Typography className={classes.restitutionPageTitle} component="h3">
                  Résultats du test
                </Typography>
                <PieDiagramContainer userResponse={userResponse} />
              </>
            )}
            {userResponse &&
              (test?.attributes?.diagram?.data?.id === 3 ||
                test?.attributes?.diagram?.data?.id === 5 ||
                test?.attributes?.diagram?.data?.id === 4) && (
                <>
                  <Typography className={classes.restitutionPageTitle} component="h3">
                    Résultats du test
                  </Typography>
                  <HorizontalDiagramContainer
                    variables={variables}
                    userResponse={userResponse}
                    typeTest={test?.attributes?.diagram?.data?.id}
                  />
                </>
              )}
          </div>
        </>
      )}
    </LayoutResultat>
  );
};

export default withWidth()(MonResultat);
