/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Button, FormControl, MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { ContainerAdmin, MarginContainer, OaPopin } from '@orientaction/commons';
import {
  AdminTab,
  CreateEditAdmin,
  Layout,
  ModalAdminUser,
  Search,
} from '@orientaction/components';
import { useAdminUser, useAlert, useCompany } from '@orientaction/hooks';
import { MajorTest, ResponseUser } from '@orientaction/services';
import { getApiUrlByTestId, getFromLS, isMajorTest } from '@orientaction/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { publicSvgPath } from '../../utils/constants';
import AutoComplete from './AutoComplete';
import ListTabsAdmin from './ListTabsAdmin';
import style from './style';
import { apiServiceNative } from '../../utils/fetchNative';

const AdminUserResultat = () => {
  console.log('-');

  const {
    tabs,
    dialog,
    company,
    loading,
    actived,
    countTabs,
    textFilter,
    rattachement,
    currentPagination,
    setTabs,
    setCurrent,
    setCompany,
    setActived,
    oneComfirm,
    setCountTabs,
    setTextFilter,
    setOpenDrawer,
    handleCloseModal,
    setListUphierchy,
    setRattachement,
    getAdminUserList,
    setCurrentPagination,
    getAdminUserListZoneInfluance,
    reassignationErrorPopin,
    closeReassignationErrorPopin,
  } = useAdminUser();

  const { setOpenAlert, openSimpleAlert } = useAlert();

  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');

  const [loadingGetHierachy, setLoadingGetHierachy] = useState<boolean>(false);

  const [hasSubordinate, setHasSubordinate] = useState<boolean>(false);

  const [typeTestChecked, setTypeTest] = useState(null);

  const [typeObjectValue, setObjectValue] = useState<any>(null);

  const [exportLoading, setExportLoading] = useState(false);

  const testFilters: any = useSelector((state: any) => state?.api?.testFilters);

  const testSelected: any = useSelector((state: any) => state?.api?.testSelected);

  const userTestFilters: any = useSelector((state: any) => state?.api?.userTestFilters);

  const classes = style();

  const { getCompanies, companies, getCompanyUserRole, companyUserRole, getPopulateHierachy } =
    useCompany();

  const getCompagnie = async () => {
    // get and set company data into the store
    await getCompanies();
  };

  const token = getFromLS('userToken') || '';

  const getUserAdminByCompony_user_role = useCallback((data: any) => {
    setTabs(data);
    setCurrentPagination(0);
    setActived(true);
    setRattachement(true);
  }, []);

  // Memoized values start here
  // Get all tests for the first filter we use to filter response-user
  const alias = companies.find((item: any) => item.id === company);

  useMemo(() => {
    if (!dialog.open) {
      setHasSubordinate(dialog.open);
    }
  }, [dialog.open]);

  // useMemo(() => {
  //   const currentTab = new URLSearchParams(search).get('tab');
  //   if (currentTab) {
  //     getUserAdminByCompony_user_role(+currentTab);
  //   }
  // }, [search]);

  const getHierchyhasPopulate = async (id: number) => {
    setLoadingGetHierachy(true);

    try {
      const response: any = await getPopulateHierachy(id);

      setHasSubordinate(!!(response.length > 0));

      return response;
    } catch (err) {
      openSimpleAlert();
      throw err;
    } finally {
      setLoadingGetHierachy(false);
    }
  };

  useMemo(() => {
    if (dialog.open && dialog.type === 'delete') {
      getHierchyhasPopulate(+dialog.id);
    }
  }, [dialog.type, dialog.open]);

  // End of memoized values

  const getFirstAllData = (idCompany: number, roletype: string) => {
    setCountTabs({});
    setCurrentPagination(0);

    if (roletype !== 'admin' && tabs < 6) {
      setCompany({ target: { value: userConnected?.company?.id } });

      if (tabs === 1) {
        setTabs(userConnected?.company_user_role?.id + 1);
      } else {
        setTabs(tabs);
      }

      getAdminUserListZoneInfluance(
        userConnected.id,
        tabs,
        currentPagination,
        textFilter,
        actived,
        rattachement
      );
      return;
    }
    if (roletype === 'admin' && tabs < 6) {
      getAdminUserList(idCompany, tabs, currentPagination, textFilter, actived, rattachement);
    }
  };

  const addNewAdmin = () => {
    setOpenDrawer(true);

    setCurrent({
      company_user_role: tabs as number,
      upper_hierarchy: userConnected?.role?.type !== 'admin' ? userConnected?.id : '',
      company: company as number,
      isUpdate: false,
      isView: false,
      Direction: userConnected.Direction || null,
      rattachement: true,
    });

    const listEmpty = [];

    if (userConnected?.role?.type !== 'admin') {
      listEmpty.push({
        ...userConnected,
      });
    }

    setListUphierchy(listEmpty);
  };

  const exportDataHandle = async () => {
    setExportLoading(true);
    try {
      const isTestMajor = isMajorTest(testSelected.id);
      if (isTestMajor) {
        const apiUrl = await getApiUrlByTestId(testSelected.id);
        MajorTest.getMajorTestResults(
          company,
          apiUrl,
          token,
          testFilters,
          {
            isRattached: userTestFilters.isRattached,
            isActive: userTestFilters.isActive,
          },
          'none',
          true
        );
      } else {
        ResponseUser.exportResponseUsers(
          token,
          testSelected.id,
          userTestFilters.isRattached,
          userTestFilters.isActive,
          testFilters
        );
      }
      setOpenAlert(
        true,
        `Vous allez recevoir un mail avec un lien pour télécharger l’export`,
        'info'
      );
    } catch (error) {
      openSimpleAlert(
        'Le filtre que vous avez sélectionné ne fonctionne pas avec les caractères que vous avez entrés.'
      );
    } finally {
      setExportLoading(false);
    }
  };

  const handleKeywordChange = (searchKeyword: string) => {
    setTextFilter(searchKeyword);
  };

  const getCategoriesTest = async () => {
    const response = await apiServiceNative.get(`/api/category-tests?populate=tests`);
    sessionStorage.setItem('categories-test', JSON.stringify(response));
  };

  useEffect(() => {
    getCompagnie();
    getCategoriesTest();
  }, []);

  useEffect(() => {
    getFirstAllData(company, userConnected?.role?.type);
  }, [company, tabs, userConnected?.role?.type, textFilter, actived, rattachement]);

  useEffect(() => {
    if (companyUserRole.length === 0) {
      getCompanyUserRole();
    }
  }, []);

  return (
    <Layout isaddBackground={true}>
      <MarginContainer>
        <div className={classes.bgGrey}>
          <MarginContainer>
            <ContainerAdmin rootStyleOverride={classes.overrideMargin}>
              <Grid
                container={true}
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Grid item={true}>
                  <Typography
                    variant="h2"
                    style={{
                      color: '#0E1247',
                    }}
                    data-cy="text-admin">
                    Utilisateurs et résultats
                  </Typography>
                </Grid>

                {userConnected?.role?.type === 'admin' && (
                  <Grid item={true}>
                    <Grid container={true} spacing={2} direction="row" alignItems="center">
                      <Grid item={true}>
                        <Typography
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: '#0E1247',
                          }}>
                          Espace entreprise sélectionné
                        </Typography>
                      </Grid>

                      <Grid item={true}>
                        <FormControl variant="outlined" style={{ minWidth: '25vh' }}>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={company}
                            onChange={setCompany}
                            placeholder="Entreprise"
                            className={classes.formControlSelect}
                            data-cy="select-company">
                            {companies
                              .sort((a: any, b: any) => a.id - b.id)
                              .map((companyToShow: any, index: number) => {
                                return (
                                  <MenuItem
                                    value={companyToShow.id}
                                    key={companyToShow.id}
                                    data-cy={`company-${index}`}>
                                    {companyToShow.attributes.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </ContainerAdmin>
          </MarginContainer>
        </div>

        <div>
          <ContainerAdmin rootStyleOverride={classes.overrideMargin}>
            <Grid container={true} justifyContent="space-between" alignItems="center">
              <Grid item={true} lg={9} xl={9} md={9} sm={12} xs={12}>
                <AdminTab
                  callBack={getUserAdminByCompony_user_role}
                  countTabs={countTabs}
                  alias={alias}
                  viewDisable={!!actived}
                />
              </Grid>

              <Grid item={true} lg={3} xl={3} md={3} sm={12} xs={12}>
                {tabs === 6 && (
                  <div className={classes.flexLine}>
                    <AutoComplete
                      setTypeTest={setTypeTest}
                      setObjectValue={setObjectValue}
                      typeObjectValue={typeObjectValue}
                      idCompany={company}
                      IfTabs={tabs === 6}
                    />
                    <Button
                      variant="outlined"
                      className={classes.btn}
                      disabled={exportLoading}
                      onClick={exportDataHandle}
                      data-cy="export-result">
                      <img
                        src={`${publicSvgPath}/vector.svg`}
                        alt="search icon"
                        style={{ marginRight: 10 }}
                      />
                      <span>{exportLoading ? 'Exporter...' : 'Exporter'}</span>
                    </Button>
                  </div>
                )}

                <Search
                  addNew={addNewAdmin}
                  isResult={!!(tabs === 6)}
                  wordFilter={textFilter}
                  handleFilterChange={handleKeywordChange}
                />
              </Grid>
            </Grid>
          </ContainerAdmin>

          <ContainerAdmin rootStyleOverride={classes.overrideMargin}>
            <ListTabsAdmin typeTestChecked={typeTestChecked} />
          </ContainerAdmin>
        </div>

        <CreateEditAdmin alias={alias} />

        <ModalAdminUser
          open={!!dialog.open}
          onCancel={handleCloseModal}
          onConfirm={oneComfirm}
          textCancel={dialog?.textCancel || 'Annuler'}
          title={dialog?.title || ''}
          textValidate={loading ? 'Chargemment ...' : dialog?.textBtn}
          type={dialog.type}
          loadingGetHierachy={loadingGetHierachy}
          hasSubordinate={hasSubordinate}>
          <div className={classes.modalContent}>
            <Typography variant="h6" className={classes.modalText}>
              {dialog?.paragraph || ''}
            </Typography>
          </div>
        </ModalAdminUser>
        <OaPopin
          open={reassignationErrorPopin}
          withCancel={false}
          title=""
          confirmText="OK"
          handleConfirm={closeReassignationErrorPopin}
          handleClose={closeReassignationErrorPopin}>
          <div className={classes.modalContent}>
            <Typography variant="h6" className={classes.modalText}>
              Vous ne pouvez pas réassigner tous les comptes de ce consultant : les prestations du
              nouveau consultant ne sont pas les mêmes
            </Typography>
          </div>
        </OaPopin>
      </MarginContainer>
    </Layout>
  );
};

export default AdminUserResultat;
