import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  btnPrimary: {
    background: '#000A8C',
    textTransform: 'none',
  },
  separator: {
    margin: '20px 0px',
    width: '100%',
    position: 'relative',
    height: '2px',
    background: '#E4E4F2',
    display: 'block',
  },
  formInput: {
    width: '100%',
    '& div fieldset': {
      borderColor: '#E4E4F2',
      top: '-6px',
    },
    '& div input': {
      paddingTop: 12,
      paddingBottom: 12,
      fontSize: 14,
    },
  },
  label: {
    fontSize: 14,
    marginBottom: 10,
  },
  card: {
    padding: '20px 30px',
    background: 'white',
    borderLeft: '10px solid #E6E6E6',
    borderRadius: 5,
  },
  closeBtn: {
    fontSize: 26,
    margin: 8,
    border: '1px solid #E4E4F2',
    borderRadius: '50%',
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    cursor: 'pointer',
  },
  section1: {
    background: '#F1F1F1',
    padding: '15px 15px 4px',
    borderRight: '1px solid #E4E4F2',
    borderBottom: '1px solid #E4E4F2',
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingBottom: '4px',
  },
  section2: {
    height: 'auto',
    borderBottom: '1px solid #E4E4F2',
    background: '#F1F1F1',
    display: 'flex',
    alignItems: 'center',
  },
  section3: {
    minHeight: '90vh',
    borderRight: '1px solid #E4E4F2',
    background: '#F1F1F1',
    maxHeight: '90vh',
    height: '90vh',
  },
  section4: {
    background: '#F1F1F1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 50,
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '90vh',
    width: '100%',
  },
  icon: {
    width: 15,
    height: 15,
  },
  title2: {
    fontFamily: 'ITC Avant Garde Gothic Std Demi',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'none',
    textWrap: 'nowrap',
  },
  wrapbutton: {
    position: 'relative',
    paddingBottom: '4px',
    '&:hover $tooltipLabel': {
      visibility: 'visible',
      opacity: 1,
    },

    '&.active $iconButton': {
      background: 'none',
      border: '1px solid #E63C3C',
      padding: '0',
      '& svg': {
        '& path': {
          fill: '#E63C3C',
        },
      },
    },

    '&:first-child': {
      '& $tooltipLabel': {
        width: '140px',
        left: '-30px',
        transform: 'none',
      },
    },

    '&:nth-of-type(2)': {
      '& $tooltipLabel': {
        width: '165px',
      },
    },
  },
  tooltipLabel: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 800,
    backgroundColor: '#E63C3C',
    color: 'white',
    padding: '10px 12px 9px',
    borderRadius: theme.shape.borderRadius,
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s, opacity 0.5s linear',
    zIndex: 1,
    cursor: 'pointer',
    textWrap: 'nowrap',
    width: 'auto !important',
  },
  iconButton: {
    position: 'relative',
    width: '39px',
    height: '39px',
    borderRadius: '5px',
    '&:hover': {
      background: 'none',
      border: '1px solid #E63C3C',
      padding: '0',
      '& svg': {
        '& path': {
          fill: '#E63C3C',
        },
      },
    },
  },
  iconS: {
    fontSize: 17,
  },
  containerIconS: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  containerPageNumber: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
  formInputNumber: {
    background: 'transparent',
    '& div fieldset': {
      borderColor: 'transparent',
      top: '-6px',
    },
    '& div input': {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: 14,
      textAlign: 'center',
      width: 22,
    },
    '& .MuiOutlinedInput-root': {
      background: 'transparent',
    },
  },
  containerGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  iconbig: {
    width: 19,
    height: 19,
  },
  btnFull: {
    borderRadius: 5,
    color: '#000A8C',
    width: '188px',
    padding: '15px 25px',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  container: {
    padding: '1px',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #E4E4F2',
    cursor: 'pointer',
  },

  textFooter: {
    color: '#B0B5FF',
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '14px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnOutFilled: {
    borderRadius: 5,
    color: 'white',
    width: '100%',
    border: '1px solid white',
    textTransform: 'none',
  },
  title: {
    color: '#000A8C',
    textAlign: 'center',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    textTransform: 'none',
    lineHeight: 1,
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      marginTop: 12,
      fontSize: '18px',
      padding: '0px 10px',
    },
  },
  titleStatus: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'italic',
    fontWeight: 600,
    lineHeight: '14px',
    margin: '5px 0px',
  },
  containerCover: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF',
    borderRadius: 5,
    borderLeft: '12px solid #757788',
    padding: '141% 0px 0px',
    position: 'relative',
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
    },
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  containerElement: {
    height: '80%',
    width: '80%',
    borderRadius: 5,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
  },
  spanText: {
    color: '#FFF',
    textAlign: 'right',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '14px',
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'white',
    textTransform: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    position: 'absolute',
    top: '20px',
    left: '40px',
    [theme.breakpoints.down('xs')]: {
      top: '25px',
      left: 'auto',
    },
  },
  btn2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'white',
    textTransform: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    position: 'relative',
    margin: '30px auto 30px',
    maxWidth: '1280px',
    flex: 1,
  },
  containerDiv: {
    position: 'relative',
    padding: 20,
    margin: '20px 0px 40px',
  },
  containerModel: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 0px 50px',
    padding: '0px 15px',
  },
  colorPrimary: {
    backgroundColor: '#000A8C',
    height: '15px',
    borderRadius: '60px',
    [theme.breakpoints.down('xs')]: {
      height: '8px',
    },
  },
  barColorPrimary: {
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    height: '15px',
    borderRadius: '60px',
    [theme.breakpoints.down('xs')]: {
      height: '8px',
    },
  },
  progressBar: {
    width: '50%',
    margin: '30px auto auto auto',
    maxWidth: '1280px',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto 15px auto auto',
    },
  },
  btnHeader: {
    display: 'flex',
  },
  ebookContainer: {
    width: 570,
    backgroundColor: '#FFFFFF',
    height: 'auto',
    padding: 35,
  },
  imgCover: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
  menuContainer: {
    position: 'absolute',
    right: 0,
    zIndex: 2,
  },
  menu: {
    position: 'relative',
    padding: 20,
    background: '#C2C2C2',
    width: 500,
    borderRadius: 5,
  },
  polygoneTriangle: {
    display: 'block',
    clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
    width: 20,
    height: 15,
    background: '#C2C2C2',
    position: 'absolute',
    right: 60,
  },
  btnicon: {
    width: 20,
    height: 'auto',
  },
  btnAddBlock: {
    color: 'white',
    textTransform: 'none',
    cursor: 'pointer',
    position: 'relative',
    textWrap: 'nowrap',
    padding: 20,
  },
  titleItem: {
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 500,
    marginTop: '.375rem',
    marginBottom: '.375rem',
    color: '#5B5B5B',
    fontFamily: 'Poppins',
  },
  titleBlock: {
    fontSize: 17,
    textAlign: 'left',
    color: '#292929',
    fontFamily: 'Poppins',
    marginTop: 0,
  },
  imgBlock: {
    width: '30%',
    height: 'auto',
    marginTop: 10,
  },
  containerBlock: {
    background: 'white',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  containerBlock2: {
    position: 'relative',
    margin: '10px 0px',
  },
  blockNumber: {
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    left: '-10%',
    transform: 'translateY(-50%)',
  },
  blockContent: {
    position: 'relative',
  },
  editArea: {
    width: 'calc(100% - 20px)',
    fontWeight: 'normal',
    padding: '10px',
    fontFamily: 'Open Sans',
    color: 'black',
    background: '#F1F1F1',
    borderRadius: 5,
    resize: 'none',
    fontSize: 16,
    border: 'none',
    outline: 'none',
  },
  selectLine: {
    background: '#ECECEC',
    width: 'auto',
    marginLeft: 10,
    border: 'none',
  },
  enclopedieContainer: {
    display: 'flex',
    background: '#f1f1f1',
    padding: '5px 10px',
  },
  iconEnc: {
    width: 25,
    marginTop: 30,
  },
  iconBtnImage: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '0%',
    right: '-3%',
  },
  titleParagraphe: {
    color: '#272727',
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  containerSommaire: {
    background: '#F6F6F6',
    padding: '11px 16px',
  },
  titleSommaire: {
    fontSize: 16,
    fontFamily: 'Poppins',
    fontWeight: 600,
    color: '#292929',
    marginBottom: 10,
  },
  subtitleSommaire: {
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 600,
    paddingLeft: 15,
    color: '#292929',
    marginBottom: 10,
  },
  containerEbookParent: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: 40,
  },
  containerEbook: {
    maxWidth: 650,
    width: '100%',
    background: 'white',
    padding: 60,
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
  },
  coverEbook: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
  titleUser: {
    fontSize: 26,
    color: '#000A8C',
    fontFamily: 'Poppins',
    fontWeight: 700,
    lineHeight: '36px',
    padding: '10px 0px 5px',
  },
  subtitleUser: {
    fontSize: 18,
    color: '#000A8C',
    fontFamily: 'Poppins',
    fontWeight: 700,
    lineHeight: '25px',
  },
  blockContentTextNormal: {
    color: '#151528',
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: 'normal',
    wordBreak: 'break-word',
    '& *': {
      fontFamily: 'Open Sans !important',
    },
    '& h2': {
      fontSize: 28,
    },
    '& h5': {
      fontSize: 14,
    },
  },
  qcmTitle: {
    background: '#F6F7FF',
    color: '#000A8C',
    fontSize: 16,
    fontFamily: 'Poppins',
    padding: '7.5px 10px',
    fontStyle: 'italic',
    fontWeight: 700,
  },
  labelQCM: {
    textTransform: 'none',
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#000A8C',
    marginLeft: 8,
  },
  titleSommaireUser: {
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 600,
    color: '#000A8C',
    marginTop: 30,
    lineHeight: 'normal',
    marginBottom: 10,
  },
  subtitleSommaireUser: {
    fontSize: 16,
    fontFamily: 'Poppins',
    fontWeight: 600,
    paddingLeft: 15,
    color: '#000000',
    marginBottom: 10,
  },
  containerSommaireUser: {
    background: 'transparent',
    padding: '15px 0px',
  },
  iconC: {
    borderRadius: 5,
    width: 16,
    height: 16,
    backgroundColor: 'white',
    border: '1px solid #000A8C',
    padding: 2,
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: 'transparent',
    borderRadius: 5,
    border: '1px solid #000A8C',
    padding: 2,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      content: '""',
      backgroundColor: '#000A8C',
      borderRadius: 4,
    },
  },
  containerChildrenSommaire: {
    '& > div:first-child': {
      marginTop: '15px !important',
    },
  },
}));
