import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timelineTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      padding: 0,
      width: '100%',
      '&:hover': {
        color: 'black',
        '& .flexText': {
          opacity: 1,
        },
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
        width: '100%',
        justifyContent: 'space-between',
        '&:hover': {
          color: 'black',
          '& .flexText': {
            opacity: 1,
          },
        },
      },
      [theme.breakpoints.down(800)]: {
        height: '45px',
      },
    },
    customDot: {
      backgroundColor: '#fff',
      padding: 2,
      marginTop: 12,
    },
    customConector: {
      width: 3,
      backgroundColor: 'transparent',
      backgroundSize: '3px 10px',
      backgroundImage: 'radial-gradient(rgb(255 255 255) 0%, rgb(255 255 255) 16%, #ff141400 42%)',
    },
    labelPeriod: {
      fontFamily: 'Poppins',
      fontSize: 14,
      fontWeight: 400,
      padding: '8px 4px',
      margin: 0,
      cursor: 'pointer',
      borderRadius: 3,
    },
    whenMobil: {
      position: 'relative',
      left: 0,
      border: '1px solid red',
    },
    isHighlighted: {
      background: '#333B9F',
      color: 'white',
      '&::marker': {
        color: '#333B9F',
      },
    },
    rootAccordion: {
      position: 'relative',
      zIndex: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      background: 'white',
      color: 'black',
      transition: '600ms',
      borderTopLeftRadius: '25px !important',
      borderTopRightRadius: '25px !important',
      [theme.breakpoints.down(800)]: {
        paddingLeft: '1vh',
      },
    },
    accordionMobileClose: {
      width: 50,
    },
    ulHistory: {
      margin: '0 1.25vh',
      padding: 0,
    },
    imgSVG: {
      width: 50,
      height: 50,
    },
    flexText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 20,
      opacity: 0,
    },
    btnCard: {
      borderRadius: 50,
      display: 'flex',
      margin: '20px auto',
      textTransform: 'none',
      padding: '0px 0px 0px 0px',
      marginBottom: 0,
      height: 'auto',
      minWidth: 60,
      '& .MuiButton-label .MuiButton-endIcon': {
        margin: 0,
      },
      '&:hover': {
        '& .MuiButton-label > span': {
          display: 'flex',
        },
      },
      transition: 'display 2s ease, width 2s',
    },
    labelSpan: {
      padding: '0px 0px 0px 0px',
      margin: '0px 20px 0px 30px',
      display: 'none',
      transition: 'display 0.5s ease, width 0.5s',
      fontFamily: '"ITC Avant Garde Gothic Std Normal"',
      fontSize: 16,
      color: '#000E8C',
    },
    ulHistory2: {
      paddingInlineStart: 25,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      marginTop: 7,
    },
    divUlHistory2: {
      width: 'auto',
      display: 'block',
      margin: 0,
      background: 'white',
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      maxHeight: 350,
      overflow: 'scroll',
    },
    bottomRadius: {
      borderRadius: 30,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    bottomTop: {
      borderRadius: 30,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    displayFlex: {
      display: 'flex',
    },
    displayNone: {
      display: 'none',
    },
    traitGrey: {
      width: '85%',
      display: 'block',
      margin: 'auto',
      height: 1.2,
      backgroundColor: 'grey',
    },
    marginTopNone: {
      marginTop: '0px !important',
      '& >span': {
        border: '1px solid #00000026',
        borderRadius: '30px',
      },
    },
    marginBlockEnd: {
      marginBlockEnd: '0px !important',
    },
    divUlHistory2Mobile: {
      position: 'absolute',
      width: '100%',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      paddingBottom: 5,
    },
    italic: {
      fontStyle: 'italic',
    },
  })
);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingBottom: '2vh',
  },
}))(MuiAccordionDetails);
