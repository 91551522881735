/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import HorizontalDiagramContainer from '@orientaction/components/Beneficiary/Result/Horizontal';
import PieDiagramContainer from '@orientaction/components/Beneficiary/Result/Pie';
import SimplePie from '@orientaction/components/Beneficiary/Result/SimplePie';
import { LayoutResultat } from '@orientaction/layouts';
import { Chart } from 'chart.js';
// import "chartjs-plugin-piechart-outlabels";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useResponseUser from '../../hooks/useResponseUser';
import { useStyles } from './style';

Chart.register(ChartDataLabels);

const Resultats = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [test, setTest] = useState<any>();
  const [variables, setVariables] = useState([]);
  const [userResponse, setUserResponse] = useState(undefined);
  const { getResponseTestUser, getTestVariables } = useResponseUser();
  const dataParams: any = useParams();
  const [loadPage, setLoadPage] = useState(true);

  useEffect(() => {
    (async () => {
      if (dataParams.id) {
        setLoadPage(true);
        const oneResponseTest = await getResponseTestUser(dataParams.id);
        const variableResponse = await getTestVariables(oneResponseTest.attributes.test.data.id);
        setVariables(variableResponse.data);
        setUserResponse(oneResponseTest);
        setTest(oneResponseTest.attributes.test.data);
        setLoadPage(false);
        console.log({variable: variableResponse.data, response: oneResponseTest})
      }
    })();
  }, []);

  const classes = useStyles();

  const isSimplePie = test?.attributes?.diagram?.data?.id === 1;

  return (
    <LayoutResultat
      showBottomLines={!(isMobile && isSimplePie)}
      showToplines={!(isMobile || isSimplePie)}
      isGradient={true}
      isNew2={false}
      isTransparent={true}>
      {loadPage ? (
        <div className={classes.bodycenter}>
          <CircularProgress style={{ color: 'white' }} />
        </div>
      ) : (
        <div className={classes.paddingContent}>
          {userResponse && isSimplePie && <SimplePie userResponse={userResponse} />}
          {userResponse && test?.attributes?.diagram?.data?.id === 2 && (
            <>
              <Typography className={classes.restitutionPageTitle} component="h3">
                Résultats du test
              </Typography>
              <PieDiagramContainer userResponse={userResponse} />
            </>
          )}
          {userResponse &&
            (test?.attributes?.diagram?.data?.id === 3 ||
              test?.attributes?.diagram?.data?.id === 5 ||
              test?.attributes?.diagram?.data?.id === 4) && (
              <>
                <Typography className={classes.restitutionPageTitle} component="h3">
                  Résultats du test
                </Typography>
                <HorizontalDiagramContainer variables={variables} userResponse={userResponse} typeTest={test?.attributes?.diagram?.data?.id} />
              </>
            )}
        </div>
      )}
    </LayoutResultat>
  );
};

export default withWidth()(Resultats);
