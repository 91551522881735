import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  resultPaperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90vw',
    zIndex: 999,
    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh',
    },
  },
  diagramContainer: {
    padding: '15px 40px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  detailsContainer: {
    padding: '5px 50px',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    '& p': {
      marginTop: '0px !important',
      marginBottom: '0px !important',
    },
  },
  shareLabelText: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    color: theme.palette.primary.light,
  },
  resultTextParagraph: {
    fontFamily: 'Poppins',
    fontSize: 26,
    fontStyle: 'normal',
    fontWeight: 500,
    zIndex: 99,
    '& p': {
      marginBottom: 10,
    },
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 20,
      marginRight: 20,
      fontSize: 18,
      textAlign: 'center',
    },
    '& *': {
      color: '#fff !important',
    },
  },
  variableImageContainer: {
    width: '100%',
    paddinLeft: 50,
  },
  diagramContent: {
    width: '100%',
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
      paddingRight: 0,
    },
  },
  button: {
    margin: '25px 0',
    color: '#fff',
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 600,
    width: '25%',
    [theme.breakpoints.down('md')]: {
      width: '45%',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    '&:hover': {
      backgroundColor: '#fff',
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
    },
    '&:focus': {
      background: '#fff',
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  studyRestitutionShareButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  studyRestitutionShareButton: {
    color: theme.palette.primary.main,
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: '#fff',
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 600,
    width: 'fit-content',
    height: 30,
    marginTop: -6,
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: '#fff',
      border: `1px solid #fff`,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.dark,
      color: '#fff',
      border: `1px solid #fff`,
    },
  },
  shareIconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  socialMediaIconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
